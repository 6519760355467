import React, { useEffect, useState } from "react";
import "../../styles/Quiz.css";
import AnswerCard from "./AnswerCard";
import fb from "../../utils/firebase";
import { onValue, ref } from "firebase/database";

export default function Quiz() {
  const [questions, setQuestions] = useState([]);
  const [indexQ, setIndexQ] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60); // Initial timer value
  const [timerRunning, setTimerRunning] = useState(false);

  // Function to start the timer
  const startTimer = () => {
    setTimeLeft(60); // Reset timer to initial value
    setTimerRunning(true);
  };

  // Function to handle timeout
  const handleTimeout = () => {
    alert("Süre bitti hacım öldün, çık");
    window.location.href = "https://pekihacim.app"; // Redirect to homepage
  };

  // Effect to start timer when indexQ changes
  useEffect(() => {
    startTimer(); // Start the timer when a new question is displayed
  }, [indexQ]);

  // Effect to handle timer countdown
  useEffect(() => {
    if (timerRunning) {
      const timer = setTimeout(() => {
        if (timeLeft === 0) {
          handleTimeout(); // Handle timeout when time reaches 0
        } else {
          setTimeLeft((prevTime) => prevTime - 1);
        }
      }, 1000);

      // Cleanup function to stop the timer when component unmounts or timerRunning becomes false
      return () => clearTimeout(timer);
    }
  }, [timeLeft, timerRunning]);

  const onClickHandler = (choice) => {
    if (questions?.length - 2 < indexQ) {
      console.log("Questions finished");
      return;
    }
    setIndexQ((prev) => prev + 1);
    setTimerRunning(false); // Reset timer when user selects an option
  };

  return (
    <div className="App">
      <body className="Quiz-body">
        <h1 className="Quiz-title">Peki Hacım? #{indexQ + 1}</h1>
        {questions?.length > 0 ? (
          <div className="Quiz-answer-box">
            <AnswerCard
              onClickHandler={onClickHandler}
              choice="A"
              text={questions[indexQ]?.A}
            />
            yoksa
            <AnswerCard
              onClickHandler={onClickHandler}
              choice="B"
              text={questions[indexQ]?.B}
            />
          </div>
        ) : (
          <div>Loading...</div>
        )}
        <div onClick={() => setIndexQ(0)} className="Quiz-button">
          Oyunu bitir
        </div>
        <div className="Timer">Kalan süre: {timeLeft} saniye</div>
      </body>
    </div>
  );
}



