import React, { useEffect } from "react";
import "../../styles/Home.css";
import { Link } from "react-router-dom";

export default function index() {
  return (
    <div className="App">
      <body className="Home-body">
        <h1 className="Home-title">Peki Hacım?</h1>
        <subtitle className="Home-subtitle">
          GELENEKSEL BİR BU MU? YOKSA ŞU MU? SEÇİM OYUNU
        </subtitle>
        <Link to={"notice"} className="Home-button">
          Şimdi Oyna
        </Link>
        <p className="Home-info-text">
          Peki Hacım?, oyunun oynanışı gerçekten çok basit. "Şimdi Oyna"
          düğmesine tıklayarak başlıyorsun ve sonra ekranda iki farklı kart
          beliriyor, bunlar farklı olayları veya durumları temsil ediyor. Sen de
          kartlardan birini seçmek için üzerine tıklıyorsun. Sonra oyun,
          seçebileceğin yeni kartlara geçiyor. Ve bu süreç, oyunu bırakmaya
          karar verene kadar devam ediyor. Oyunun minimalist tasarımı ve
          sezgisel kart seçimi sayesinde 'Peki Hacım?', geleneksel bir 'bu mu?
          yoksa şu mu?' seçim temalı kart oyununu keyifli bir deneyim haline
          getiriyor.
        </p>
        <p className="Home-info-text2">
          ** Ek olarak, 'Peki Hacım?' oyunu küçükler için uygun değildir.
          Kartların içeriğinde zaman zaman absürt, uygunsuz ve iğrenç mide
          bulandırıcı olaylar veya durumlar olabilir. **
        </p>
      </body>
    </div>
  );
}
