import React from "react";
import "../../styles/Notice.css";
import { Link } from "react-router-dom";

export default function index() {
  return (
    <div className="App">
      <body className="Notice-body">
        <h1 className="Notice-title">OYUNA BAŞLAMADAN ÖNCE</h1>
        <p className="Notice-info-text">
          Peki Hacım? oyununu oynayabilmeniz için belirli bir yaşa sahip olmanız
          gerektiğini unutmayın. Bu oyun, bazı kartlarında zaman zaman absürt,
          uygunsuz ve iğrenç mide bulandırıcı olaylar veya durumlar içerebilir.
        </p>
        <p className="Notice-info-text">
          Lütfen aşağıdaki beyanı okuyup onaylayın:
        </p>
        <ul className="Notice-list">
          <li>Ben, oyunu oynamak için yeterli yaşta olduğumu beyan ederim.</li>
          <li>
            Ebeveyn ya da yasal bir vasim tarafından denetlenen bir çocuğumun
            oyunu oynamasına izin veriyorsam, çocuğun uygun yaşta olduğunu teyit
            ederim.
          </li>
          <li>
            Uygunsuz içeriklere karşı dikkatli olacağım ve rahatsız edici veya
            uygunsuz içeriklerle karşılaşırsam oyunu hemen durduracağım.
          </li>
          <div className="Notice-buttons">
            <Link to={"/quiz"} className="Notice-button">
              Onayla
            </Link>
            <Link to={"/"} className="Notice-button">
              Çıkış
            </Link>
          </div>
        </ul>
      </body>
    </div>
  );
}
