import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Notice from "./pages/Notice";
import Quiz from "./pages/Quiz";
import "./styles/Home.css";

export default function App() {
  return (
    <div className="Home-body">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="notice" element={<Notice />} />
        <Route path="quiz" element={<Quiz />} />
      </Routes>
    </div>
  );
}
