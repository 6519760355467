// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDhDazxAisSCTfXYNY2NUzUZawlA7I99Bk",
  authDomain: "pekihacim-f0eff.firebaseapp.com",
  databaseURL: "https://pekihacim-f0eff-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "pekihacim-f0eff",
  storageBucket: "pekihacim-f0eff.appspot.com",
  messagingSenderId: "920133928552",
  appId: "1:920133928552:web:749c1b0aab7198b79a5ddd",
  measurementId: "G-LM5GDWWVBW"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getDatabase(app);

export default { app, analytics, db };
